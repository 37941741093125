export interface Point {
  x: number;
  y: number;
}

export interface LinePoint {
  t: number;
  x: number;
  y: number;
  pressure: number;
}

export const scaleFudger = 256;

const comparisonCanvas = document.createElement("canvas");
const comparisonCtx = comparisonCanvas.getContext("2d");
comparisonCanvas.width = 1024;
comparisonCanvas.height = 1024;
comparisonCanvas.style.background = "pink";
comparisonCtx!.lineWidth = (16 / 1024) * 2 * 2 * 2 * scaleFudger;
comparisonCtx!.lineCap = "round";
comparisonCtx!.lineJoin = "round";

/*
window.addEventListener("load", () => {
  document.body.appendChild(comparisonCanvas);
});
*/

export function compareLines(a: Point[], b: Point[]) {
  const ctx = comparisonCtx!;
  ctx.save();
  ctx.globalCompositeOperation = "source-over";
  ctx.clearRect(0, 0, comparisonCanvas.width, comparisonCanvas.height);
  ctx.globalCompositeOperation = "xor";
  ctx.fillStyle = "black";

  ctx.translate(512, 512);
  ctx.scale(512, 512);
  ctx.scale(1 / scaleFudger, 1 / scaleFudger);

  drawLine(ctx, b);
  const dataB = ctx.getImageData(
    0,
    0,
    comparisonCanvas.width,
    comparisonCanvas.height
  ).data;
  let targetCount = 0;
  for (let i = 0; i < dataB.length; i += 4) {
    if (dataB[i + 3] > 0) {
      targetCount++;
    }
  }
  drawSmoothedLine(ctx, a);
  let dataA = ctx.getImageData(
    0,
    0,
    comparisonCanvas.width,
    comparisonCanvas.height
  ).data;
  let missCount = 0;
  for (let i = 0; i < dataA.length; i += 4) {
    if (dataA[i + 3] > 0) {
      missCount++;
    }
  }

  ctx.restore();
  return 1 - Math.min(1, (missCount / targetCount) ** 1.5);
}

export function getArea(polygon: Point[]) {
  let sumA = 0;
  let sumB = 0;
  for (let i = 0; i < polygon.length; i++) {
    const point = polygon[i];
    const nextPoint = polygon[(i + 1) % polygon.length];
    sumA += point.x * nextPoint.y;
    sumB += point.y * nextPoint.x;
  }
  return Math.abs(sumA - sumB) / 2;
}

export function drawLine(ctx: CanvasRenderingContext2D, line: Point[]) {
  if (line.length < 2) {
    return;
  }
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(line[0].x, line[0].y);

  for (let i = 1; i < line.length; i++) {
    ctx.lineTo(line[i].x, line[i].y);
  }
  ctx.stroke();
  ctx.restore();
}

export function drawSmoothedLine(ctx: CanvasRenderingContext2D, line: Point[]) {
  if (line.length < 2) {
    return;
  }
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(line[0].x, line[0].y);

  for (let i = 1; i < line.length - 2; i++) {
    const xc = (line[i].x + line[i + 1].x) / 2;
    const yc = (line[i].y + line[i + 1].y) / 2;
    ctx.quadraticCurveTo(line[i].x, line[i].y, xc, yc);
  }
  ctx.quadraticCurveTo(
    line[line.length - 2].x,
    line[line.length - 2].y,
    line[line.length - 1].x,
    line[line.length - 1].y
  );
  ctx.stroke();
  ctx.restore();
}
