import React from "react";
import {
  LinePoint,
  Point,
  drawSmoothedLine,
  compareLines,
  scaleFudger,
} from "./utils";

const devicePixelRatio = 1;

const targetShapes = [
  [
    { x: -0.8, y: 0 },
    { x: -0.8, y: 0 },
  ],
  [
    { x: 0, y: -0.7 },
    { x: 0, y: 0.7 },
  ],
  [
    { x: -0.5, y: 0 },
    { x: -0.5, y: 0.5 },
    { x: 0.5, y: 0 },
    { x: -0.5, y: 0 },
  ],
];

for (let i = 0; i < 10; i++) {
  const n = (3 + Math.random() * 6) | 0;
  const shape = [];
  for (let j = 0; j < n; j++) {
    shape.push({
      x: (Math.random() - 0.5) * (0.5 + Math.random()),
      y: (Math.random() - 0.5) * (0.5 + Math.random()),
    });
  }
  targetShapes.push(shape);
}

for (let i = 0; i < targetShapes.length; i++) {
  const shape = targetShapes[i];
  for (let j = 0; j < shape.length; j++) {
    const point = shape[j];
    point.x *= scaleFudger;
    point.y *= scaleFudger;
  }
}

interface State {
  showModal: boolean;
}

export class Canvas extends React.Component<{}, State> {
  private history: { score: number; line: Point[] }[];
  private ratioX: number = 1;
  private ratioY: number = 1;
  private stoppedDrawing: number = 0;

  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;
  private currentLine: LinePoint[];
  private isCurrentlyDrawingALine: boolean = false;
  private targetLine: Point[];
  private currentScore: number | null = null;
  private bestScore: number = 0;
  private bestLine: Point[] | null = null;
  private width: number = 1;
  private height: number = 1;
  private neverDrawedBefore: boolean = true;
  private scoreLog: number[];

  constructor(props: {}) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.canvas = document.createElement("canvas");
    this.canvas.style.background = "#fffcebdd";
    this.canvas.style.cursor = "crosshair";
    this.canvas.style.touchAction = "none";
    const context = this.canvas.getContext("2d");
    this.canvas.width = 2000;
    this.canvas.height = 1000;
    if (context === null) {
      throw Error("Could not initialize canvas.");
    }
    this.ctx = context;

    this.scoreLog = [];

    this.history = [];

    this.currentLine = [];

    const resize = () => {
      this.canvas.width = window.innerWidth * devicePixelRatio;
      this.canvas.height = window.innerHeight * devicePixelRatio;
      this.canvas.style.width = window.innerWidth + "px";
      this.canvas.style.height = window.innerHeight + "px";
    };

    window.addEventListener("resize", resize);
    resize();

    this.targetLine = [];
    for (const steps of [128, 6, 5, 8, 3, 4]) {
      this.targetLine = [];
      let radius = 0.5;
      let xOffset = 0;
      let yOffset = 0;
      for (let i = 0; i <= steps; i++) {
        const angle = (i / steps) * Math.PI * 2;
        this.targetLine.push({
          x: (xOffset + radius * Math.sin(angle)) * scaleFudger,
          y: (yOffset + radius * Math.cos(angle)) * scaleFudger,
        });
      }
      targetShapes.push(this.targetLine);
    }

    this.canvas.addEventListener("pointerdown", (e) => {
      this.currentLine = [];
      this.currentScore = null;
      this.isCurrentlyDrawingALine = true;
      const x = (e.clientX / window.innerWidth - 0.5) / this.ratioX;
      const y = (e.clientY / window.innerHeight - 0.5) / this.ratioY;
      this.currentLine.push({
        t: performance.now(),
        x,
        y,
        pressure: 0.5,
      });
    });

    this.canvas.addEventListener("pointerup", () => {
      this.neverDrawedBefore = false;
      this.isCurrentlyDrawingALine = false;
      this.stoppedDrawing = +new Date();
      const newScore = compareLines(this.currentLine, this.targetLine);
      this.currentScore = newScore;
      this.scoreLog.push(newScore);
      this.history = this.history.slice(-9);
      this.history.push({ score: newScore, line: this.currentLine });
      if (newScore >= this.bestScore) {
        this.bestScore = newScore;
        this.bestLine = this.currentLine;
      }

      for (const point of this.currentLine) {
        const nextShapeButton = {
          x: -this.width / 2 + 0.2 * scaleFudger,
          y: this.height / 2,
        };
        const dx = point.x - nextShapeButton.x;
        const dy = point.y - nextShapeButton.y;
        if (Math.sqrt(dx * dx + dy * dy) < 0.25 * scaleFudger) {
          this.bestScore = 0;
          this.bestLine = null;
          this.currentLine = [];
          const shapes = targetShapes.filter((x) => x !== this.targetLine);
          this.targetLine = shapes[(Math.random() * shapes.length) | 0];
          break;
        }
      }
    });

    this.canvas.addEventListener("pointermove", (e) => {
      if (!this.isCurrentlyDrawingALine) {
        return;
      }
      const x = (e.clientX / window.innerWidth - 0.5) / this.ratioX;
      const y = (e.clientY / window.innerHeight - 0.5) / this.ratioY;
      this.currentLine.push({
        t: performance.now(),
        x,
        y,
        pressure: 0.5,
      });
    });

    this.renderCanvas();
  }

  drawTarget(target: { x: number; y: number }[], alphaScale: number) {
    this.ctx.save();
    this.ctx.globalAlpha = 0.25 * alphaScale;
    this.ctx.beginPath();
    this.ctx.moveTo(target[0].x, target[0].y);
    for (let i = 0; i < target.length; i++) {
      const point = target[i];
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.stroke();
    this.ctx.setLineDash([]);
    this.ctx.lineWidth /= 4;
    this.ctx.beginPath();
    this.ctx.moveTo(target[0].x, target[0].y);
    for (let i = 0; i < target.length; i++) {
      const point = target[i];
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.stroke();
    this.ctx.restore();
  }

  renderCanvas = () => {
    requestAnimationFrame(this.renderCanvas);
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let scale = 1;
    if (this.canvas.width < this.canvas.height) {
      this.ratioX = 0.5;
      this.ratioY = this.canvas.width / this.canvas.height / 2;
      scale = this.canvas.height * this.ratioY;
    } else {
      this.ratioX = this.canvas.height / this.canvas.width / 2;
      this.ratioY = 0.5;
      scale = this.canvas.width * this.ratioX;
    }

    scale /= scaleFudger;
    this.ratioX /= scaleFudger;
    this.ratioY /= scaleFudger;

    this.ctx.save();
    this.ctx.translate(this.canvas.width / 2, this.canvas.height / 2);
    this.ctx.scale(scale, scale);
    const width = this.canvas.width / scale;
    const height = this.canvas.height / scale;
    this.width = width;
    this.height = height;

    const spacer = 64 / (scale * 4);

    const fontSize =
      ((window.innerWidth > 768 ? 1.2 : 2.2) / 20) * 1.5 * scaleFudger;

    this.ctx.strokeStyle = "#222";
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.lineWidth = spacer / 4 / 2;
    this.ctx.setLineDash([spacer, spacer]);
    //this.ctx.strokeRect(-1, -1, 2, 2);

    this.ctx.font = 2 * fontSize + "px 'Patrick Hand'";
    this.ctx.textAlign = "center";
    this.ctx.textBaseline = "middle";
    if (this.currentScore !== null) {
      this.ctx.textAlign = "center";
      this.ctx.fillText(
        Math.round(this.currentScore * 10000) / 100 + "%",
        0,
        height / 2 - spacer * 8
      );
    }

    if (!this.neverDrawedBefore) {
      this.ctx.textAlign = "left";
      this.ctx.textBaseline = "bottom";
      this.ctx.font = fontSize + "px 'Patrick Hand'";
      this.ctx.fillStyle = "#222";
      this.ctx.fillText(
        "Next shape >",
        -width / 2 + spacer * 2,
        height / 2 - spacer * 1
      );
    }

    if (this.bestLine) {
      this.ctx.textAlign = "right";
      this.ctx.textBaseline = "bottom";
      this.ctx.font = fontSize + "px 'Patrick Hand'";
      this.ctx.fillStyle = "#222";
      this.ctx.fillText(
        "Best: " + Math.round(this.bestScore * 10000) / 100 + "%",
        width / 2 - spacer * 2,
        height / 2 - spacer * 1
      );

      this.ctx.save();
      this.ctx.translate(width / 2, height / 2);
      this.ctx.setLineDash([]);
      this.ctx.lineWidth = (spacer / 4) * 2;
      this.ctx.scale(1 / 4, 1 / 4);
      this.ctx.translate(-24 * spacer, -16 * fontSize);
      drawSmoothedLine(this.ctx, this.bestLine);
      this.ctx.restore();
    }

    this.ctx.font = fontSize + "px 'Patrick Hand'";

    let alphaScale = 1;
    if (this.bestScore >= 0.98 && this.currentLine.length > 0) {
      alphaScale = Math.max(
        0,
        (this.currentLine[0].t + 1000 - performance.now()) / 1000
      );

      this.ctx.textAlign = "right";
      this.ctx.textBaseline = "middle";
      this.ctx.font = fontSize + "px 'Patrick Hand'";
      this.ctx.fillStyle = "#222";
      this.ctx.fillText(
        "98%+: No guides!",
        width / 2 - spacer * 2,
        1 - spacer * 20
      );
    }
    if (!this.isCurrentlyDrawingALine) {
      alphaScale = 1;
    }
    this.drawTarget(this.targetLine, alphaScale);
    this.ctx.setLineDash([]);
    this.ctx.globalAlpha = 0.85;
    this.ctx.lineWidth = spacer / 4;
    drawSmoothedLine(this.ctx, this.currentLine);

    if (
      !this.isCurrentlyDrawingALine &&
      this.stoppedDrawing < +new Date() - 3000
    ) {
      this.ctx.save();
      this.ctx.rotate(-0.25);
      this.ctx.translate(
        (-0.4 + Math.min(0.5, (height / scaleFudger - 2) / 2)) * scaleFudger,
        (-0.5 - 0.5 * Math.min(0.5, (height / scaleFudger - 2) / 2)) *
          scaleFudger
      );
      const jumpyScaler = 1 + 0.02 * Math.sin(+new Date() / 200);
      this.ctx.scale(jumpyScaler, jumpyScaler);
      this.ctx.textAlign = "center";
      this.ctx.textBaseline = "middle";
      this.ctx.globalAlpha = Math.min(
        1,
        (+new Date() - 3000 - this.stoppedDrawing) / 1000
      );
      this.ctx.fillText(
        "start drawing",
        -0.22 * scaleFudger,
        -0.05 * scaleFudger
      );
      this.ctx.lineWidth = fontSize / 8;
      this.ctx.translate(0, 0.05 * scaleFudger);
      drawSmoothedLine(this.ctx, [
        {
          x: 0,
          y: 0,
        },
        {
          x: 0.03 * scaleFudger,
          y: 0.03 * scaleFudger,
        },
        {
          x: 0.04 * scaleFudger,
          y: 0.08 * scaleFudger,
        },
      ]);
      this.ctx.restore();
    }

    this.ctx.restore();
  };

  public render() {
    return (
      <div
        style={{
          cursor: "crosshair",
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          background: "url(paper.jpg) repeat",
        }}
        ref={(ref) => ref && ref.appendChild(this.canvas)}
      >
        <div
          style={{
            color: "rgba(32 ,32, 32, 0.85)",
            userSelect: "none",
            pointerEvents: "none",
            position: "fixed",
            top: 32,
            left: 16,
            right: 16,
            textAlign: "center",
            fontSize: window.innerWidth < 768 ? "28pt" : "40pt",
            fontFamily: "Patrick Hand",
          }}
        >
          Practice your tablet drawing accuracy!
        </div>

        {this.state.showModal && (
          <div
            onClick={() => this.setState({ showModal: false })}
            style={{
              color: "#222",
              userSelect: "none",
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "16px",
              fontFamily: "Patrick Hand",
              zIndex: 99999,
              background: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <div
              style={{
                background: "white",
                userSelect: "auto",
                borderRadius: 8,
                padding: 32,
                margin: 16,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.26)",
                maxWidth: 480,
                fontSize: "24px",
                lineHeight: "32px",
              }}
            >
              <div style={{ paddingBottom: 32 }}>
                <strong style={{ color: "black" }}>Tablet Practice</strong> is a
                simple practicing app for improving drawing accuracy using
                Wacom-style digital drawing tablets and computer drawing pads.
              </div>
              <div style={{ paddingBottom: 64 }}>
                Try to replicate the blueprint figures as accurately as
                possible. If you're anything like me, it's much harder than it
                looks!
              </div>
              <div style={{ marginTop: 32 }}>
                Created by{" "}
                <a href="https://arkt.is" onClick={(e) => e.stopPropagation()}>
                  Sigve Sebastian Farstad
                </a>
                .
              </div>
            </div>
          </div>
        )}

        <div
          style={{
            color: "rgba(32 ,32, 32, 0.85)",
            userSelect: "none",
            position: "fixed",
            bottom: window.innerWidth < 768 ? 64 : 32,
            left: 16,
            right: 16,
            textAlign: "center",
            fontSize: window.innerWidth < 768 ? "16px" : "24px",
            fontFamily: "Patrick Hand",
            zIndex: 9999,
            pointerEvents: "none",
          }}
        >
          <a
            href="/"
            style={{ color: "rgba(0, 0, 0, 0.5)", pointerEvents: "auto" }}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                showModal: true,
              });
            }}
          >
            What is this?
          </a>
        </div>
      </div>
    );
  }
}
